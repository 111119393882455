import React, { Component } from 'react';
import './App.css';
import Typed from 'react-typed';

class Header extends Component {
  render() {
    return (
      <div className="App">
        <header id="header" className="App-header App-nav mx-auto my-0 text-uppercase">
        <div className="animated bounceInDown">
          <h1>
            Maxime Kreber
          </h1>
          </div>
           <div id="developper" className="animated bounceInLeft">
                <Typed 
                  strings={[
                    'Développeur Full-Stack',
                    'Développeur Front-End',
                    'Développeur Back-End']}
                    typeSpeed={40}
                    backSpeed={50} 
                    loop >
                </Typed>
            </div>

                <div className="animated bounceInUp">
                <h4 id="email"><i className="fas fa-envelope"></i> maxime.kreber@gmail.com</h4>
                </div>
        </header>
      </div>
    );
  }
}

export default Header;
