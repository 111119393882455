import React, { Component } from 'react';

import './App.css';
import 'react-circular-progressbar/dist/styles.css';


import NavBar from './NavBar';
import Header from './Header';
import Skill2 from './Skill2';
import Portfolio from './Portfolio';
import Footer from './Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
      <NavBar />
      <Header />
      <Skill2 />
      <Portfolio /> 
      <Footer />
      </div>
    );
  }
}

export default App;
