import React, { Component } from 'react';
import './App.css';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

class NavBar extends Component {
  render() {
    return (
              <Navbar collapseOnSelect fixed="top" expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="/">Maxime Kreber</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Container className="justify-content-md-end">
            <Nav className="mr-auto ">
              <Nav.Link href="/"><i className="fas fa-home text-white"></i></Nav.Link>
              <Nav.Link className="text-white" href="/#about">A Propos</Nav.Link>
              <Nav.Link className="text-white" href="/#portfolio">Portfolio</Nav.Link>
            </Nav>
            </Container>
          </Navbar.Collapse>
        </Navbar>
        
    );
  }
}

export default NavBar;
