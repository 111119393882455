import React, { Component } from 'react';
import './App.css';

class Footer extends Component {
  render() {
    return (
            <footer className="page-footer font-small bg-dark">
                <div className="footer-copyright text-center py-3 text-white">© 2019 Copyright: 
                    <a className="text-white" href="http://maxime-kreber.fr"> maxime-kreber.fr</a>
                </div>
            </footer>
    );
  }
}

export default Footer;
