import React, { Component } from 'react';
import './App.css';
import ProgressBar from 'react-bootstrap/ProgressBar'
import OnVisible from 'react-on-visible';

class Skill2 extends Component {
  render() {
    return (
<section id="about">
<div className="container">
  <header className="text-center">
  <OnVisible percent={10} visibleClassName="animated fadeInDown">
  <hr className="bg-dark"/>
    <h1 className="title">A Propos de moi</h1>
    <hr className="bg-dark" /></OnVisible>
    
  </header>
  <div className="row">
  
    <div className="col-lg-6">
    <OnVisible percent={10} visibleClassName="animated fadeInLeft">
      <p>Actuellement en fin de d'étude je cherche un <strong>stage</strong> pour finaliser ma formation ,
         j'ai de bonnes connaissances en <strong>PHP</strong>, MYSQL, HTML,
         CSS que je pratique depuis <strong>2012</strong> ainsi que des connaissances appronfondi en <strong>Javascript</strong>(2ans).
         </p>
        <p>
         Au niveau des Frameworks j'ai beaucoup utilisé <strong>Symfony</strong> mais j'ai quelque connaissance 
         sur Laravel et ça ne me dérangerai pas d'en apprendre plus.
         J'ai également utilisé <strong>Wordpress</strong> au cours de différents projets.
         J'ai aussi quelques bases sur <strong>Node.js</strong> avec le Framework <strong>Express.js</strong> .</p> 
         
         <p>
          Pour ce qui est des Frameworks Front-end j'utilise <strong>React.js</strong> ainsi qu'une bonne compréhension en <strong>Jquery</strong>.
          Je me suis beaucoup intéressé à <strong>Vue.js</strong> et <strong>React Native</strong> où j'ai parcouru plusieurs 
          fois la documentation sans jamais avoir pratiqué j'aimerais bien sûr en apprendre beaucoup plus.
         </p>
      </OnVisible></div>
    <div className="col-lg-6">
    <OnVisible percent={10} visibleClassName="animated fadeInRight"> 
      <div>
        <h5>PHP</h5>
        <div className="progress"> 
        <ProgressBar variant="success" now='80' label='80%' /> 
        </div>
      </div>
      <div>
        <h5>Javascript</h5>
        <div className="progress">
            <ProgressBar variant="success" now='75' label='75%' />
        </div>
      </div>
      <div >
        <h5>HTML</h5>
        <div className="progress">
        <ProgressBar variant="success" now='85' label='85%' /> 
        </div>
      </div>
      <div>
      

        <h5>CSS</h5>
            <ProgressBar variant="success" now='60' label='60%' />
      </div>
      <div>
        <h5>React.Js</h5>
        <ProgressBar className="" variant="success" now='55' label='55%' />
      </div>
      <div>
        <h5>Symfony 4</h5>
        <ProgressBar className="" variant="success" now='80' label='80%' />
      </div>
       </OnVisible>
    </div>
  </div>
</div>

</section>

);
  }
}

export default Skill2;
