import React, { Component } from 'react';
import './App.css';
import ImageZoom from 'react-medium-image-zoom'
import OnVisible from 'react-on-visible';

class Portfolio extends Component {
  render() {
    return (
            <section id="portfolio" className="App-portfolio" >
             <div class="container text-center App-nav">
             <OnVisible percent={10} visibleClassName="animated fadeInUp">
              <hr className="bg-dark"/>
                <h2>Portfolio</h2>
                <hr className="bg-dark"/>
                <h4>Quelques Créations</h4></OnVisible>
                <div class="row">
                 
                  <div class="col-sm-6 cardpadding">
                  <OnVisible percent={10} visibleClassName="animated fadeInLeft">
                    <div class="thumbnail card rounded bg-dark">
                    <ImageZoom
                      image={{
                        src: 'http://maxime-kreber.fr/img/louvre.jpg', 
                        alt: 'Demo Le Louvre',
                        className: 'card-img-top',
                      }}
                      zoomImage={{
                        src: 'http://maxime-kreber.fr/img/louvre.jpg',
                        alt: 'Demo Le Louvre'
                      }}
                    />
                    <h2 className="card-header"><strong>Le Louvre</strong></h2>
                    <div className="card-body">
                      
                      <p className="card-text">Projet Back-end réalisé avec Symfony 4 où le but était de créer une billetterie avec stripe et d'envoyer les billets par email. </p>
                      <a href="http://louvre.maxime-kreber.fr/" className="btn btn-success">Demo</a>
                      <p className="card-text">Le projet est également sur GitHub</p>
                      <a href="https://github.com/maximekreber/Le-Louvre" className="btn btn-primary">Voir le Code</a>
                      </div>
                    </div></OnVisible>
                  </div>
                  <div class="col-sm-6 cardpadding">
                  <OnVisible percent={10} visibleClassName="animated fadeInRight">
                    <div class="thumbnail card rounded bg-dark">
                    <ImageZoom
                      image={{
                        src: 'http://maxime-kreber.fr/img/blog.jpg', 
                        alt: 'Demo Blog',
                        className: 'card-img-top',
                      }}
                      zoomImage={{
                        src: 'http://maxime-kreber.fr/img/blog.jpg',
                        alt: 'Demo Blog'
                      }}
                    />
                    <h2 className="card-header"><strong>Blog d'un écrivain</strong></h2>
                    <div className="card-body">
                      
                      <p className="card-text">Un blog d'écrivain avec panel d'administration développé en php et mysql sans l'aide de framework.</p>
                      <a href="http://blog.maxime-kreber.fr/" className="btn btn-success">Demo</a>
                      <p className="card-text">Le projet est également sur GitHub</p>
                      <a href="https://github.com/maximekreber/projet3" className="btn btn-primary">Voir le Code</a>
                      </div>
                    </div></OnVisible>
                  </div>
              </div>
              </div>
            </section>
    );
  }
}

export default Portfolio;
